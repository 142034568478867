<template>
  <svg width="41" height="75" viewBox="0 0 41 75" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g filter="url(#filter0_bd_209_31)">
    <ellipse rx="27.5" ry="27.5" transform="matrix(1 -8.74228e-08 -8.74228e-08 -1 3.5 34.5)" fill="white" fill-opacity="0.6" shape-rendering="crispEdges"/>
    </g>
    <path d="M16.1998 39.0001L12.1998 35.0001L16.1998 31.0001L15.3998 29.4001L9.7998 35.0001L15.3998 40.6001L16.1998 39.0001Z" fill="#A01B5B"/>
    <defs>
    <filter id="filter0_bd_209_31" x="-34" y="0" width="75" height="75" filterUnits="userSpaceOnUse" color-interpolation-filters="sRGB">
    <feFlood flood-opacity="0" result="BackgroundImageFix"/>
    <feGaussianBlur in="BackgroundImage" stdDeviation="2"/>
    <feComposite in2="SourceAlpha" operator="in" result="effect1_backgroundBlur_209_31"/>
    <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha"/>
    <feOffset dy="3"/>
    <feGaussianBlur stdDeviation="5"/>
    <feComposite in2="hardAlpha" operator="out"/>
    <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0"/>
    <feBlend mode="normal" in2="effect1_backgroundBlur_209_31" result="effect2_dropShadow_209_31"/>
    <feBlend mode="normal" in="SourceGraphic" in2="effect2_dropShadow_209_31" result="shape"/>
    </filter>
    </defs>
  </svg>

</template>

<script>
export default {
  name: 'TenderArrowLeft'
}
</script>


<style lang="scss" scoped>
</style>
