<template>
  <div 
    class="shadow-tablet br-3 custom-border-tablet mb-5-5-tablet">
    <tender-header />

    <div 
      class="table-container"
      ref="table-container"
    >
      <div class="p-4 is-hidden-tablet custom-table">
        <TenderMobileCard
          v-for="tender in tendersList"
          :key="tender.id"
          :tender="tender"
        />
      </div>

      <TenderListTable />
      <TenderListSetup />
      <a @mouseover="scrollOnHover('right')" class="tender-arrow-right tender-arrow">
        <TenderArrowRight/>
      </a>
      <a @mouseover="scrollOnHover('left')" class="tender-arrow-left tender-arrow">
        <TenderArrowLeft />
      </a>
    </div>
  </div>
</template>

<script>
import { TenderListFullBasicMixin } from "./TenderListFullBasicMixin";
import TenderMobileCard from "./TenderMobileCard";
import TenderHeader from "./TenderHeader";
import TenderListTable from "./TenderListTable";
import TenderListSetup from "./TenderListSetup";
import TimeIcon from "@/components/icons/TimeIcon.vue";
import CloseIcon from "@/components/icons/CloseIcon.vue";
import TenderArrowRight from '@/components/icons/TenderArrowRight.vue';
import TenderArrowLeft from '@/components/icons/TenderArrowLeft.vue';
export default {
  components: {
    TenderMobileCard,
    TenderHeader,
    TenderListTable,
    TimeIcon,
    CloseIcon,
    TenderListSetup,
    TenderArrowRight,
    TenderArrowLeft,
  },
  mixins: [TenderListFullBasicMixin],
  mounted() {
    this.updateList()
    window.addEventListener('scroll', this.onChangePositionArrow)
  },
  beforeUnmount() {
    window.removeEventListener('scroll', this.onChangePositionArrow)
  },

  methods: {
    scrollOnHover(direction) {
      let content = document.querySelector(".table-container")
      content.scrollLeft = (direction == 'left') ? 0 : content.offsetWidth
    },
    onChangePositionArrow() {
      const arrows = document.querySelectorAll('.tender-arrow')
      const container =  this.$refs['table-container']
      const scrollY = window.scrollY
      let arrowHeight = 0
      arrows.forEach(item => {
        arrowHeight = item.clientHeight
        if(scrollY < (container.clientHeight   -  arrowHeight)){
          item.style.top = `${scrollY}px`
        }
      })
      
    }
  },
}
</script>

<style scoped lang="scss">
.table-container {
  position: relative;
}
.table-header-title {
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 14px;
  color: #6f6f6f;
}
.shadow-tablet {

  .tender-arrow {
    position: absolute;
    //top: 50%;
    filter: grayscale(1);
    transition: all .3s;
    &:hover {
      filter: none;
    }
  }
  .tender-arrow-left {
    left: 0;
  }
  .tender-arrow-right {
    right: 0
  }
}
</style>
