<template>
  <section class="main-wrapper">
    <div class="main-content">
      <header-vue />
      <bread-crumbs :breadcrumbItems="breadcrumbItems" />

      <div class="container text-center-mobile-only pb-1 pl-5 pr-5 pt-5 mb-4">
        <div
          class="
            is-flex is-align-items-center is-justify-content-space-between
            mb-4
          "
        >
          <h2
            class="
              main-title
              is-size-3-5-mobile
              has-text-left has-text-centered-mobile
              mr-4
            "
          >
            Тендеры
          </h2>
          <div class="has-text-right-mobile has-text-left-tablet">
            <span>
              <tender-hide-inactive />
            </span>
            <span> Cкрыть неактивные </span>
          </div>
        </div>
        <tender-list-full />
        <tender-pagination />
      </div>
    </div>

    <footer-vue />
  </section>
</template>

<script>
import HeaderVue from "@/components/common/Header.vue";
import FooterVue from "@/components/common/Footer.vue";
import BreadCrumbs from "@/components/common/BreadCrumbs.vue";
import TenderListFull from "@/home/TenderListFull/TenderListFull.vue";
import TenderHideInactive from "@/home/TenderListFull/TenderHideInactive.vue";
import TenderPagination from "@/home/TenderListFull/TenderPagination.vue";

export default {
  components: {
    HeaderVue,
    FooterVue,
    BreadCrumbs,
    TenderListFull,
    TenderHideInactive,
    TenderPagination,
  },
  name: "Tenders",
  data() {
    return {
      breadcrumbItems: [
        {
          title: "Тендеры",
          link: null,
        },
      ],
    };
  },
};
</script>
