<template>
  <svg width="40" height="75" viewBox="0 0 40 75" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g filter="url(#filter0_bd_209_31)">
    <ellipse cx="37.5" cy="34.5" rx="27.5" ry="27.5" transform="rotate(-180 37.5 34.5)" fill="white" fill-opacity="0.8" shape-rendering="crispEdges"/>
    </g>
    <path d="M24.8 39.0001L28.8 35.0001L24.8 31.0001L25.6 29.4001L31.2 35.0001L25.6 40.6001L24.8 39.0001Z" fill="#A01B5B"/>
    <defs>
    <filter id="filter0_bd_209_31" x="0" y="0" width="75" height="75" filterUnits="userSpaceOnUse" color-interpolation-filters="sRGB">
    <feFlood flood-opacity="0" result="BackgroundImageFix"/>
    <feGaussianBlur in="BackgroundImage" stdDeviation="3"/>
    <feComposite in2="SourceAlpha" operator="in" result="effect1_backgroundBlur_209_31"/>
    <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha"/>
    <feOffset dy="3"/>
    <feGaussianBlur stdDeviation="5"/>
    <feComposite in2="hardAlpha" operator="out"/>
    <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0"/>
    <feBlend mode="normal" in2="effect1_backgroundBlur_209_31" result="effect2_dropShadow_209_31"/>
    <feBlend mode="normal" in="SourceGraphic" in2="effect2_dropShadow_209_31" result="shape"/>
    </filter>
    </defs>
  </svg>
</template>

<script>
export default {
  name: 'TenderArrowRight'
}
</script>


<style lang="scss" scoped>
</style>
