<template>
  <div class="shadow custom-border-3 mb-5 has-text-left mobile-card">
    <div class="p-4">
      <b-collapse animation="slide" v-model="isOpen">
        <template #trigger="props">
          <div
            class="
              is-flex is-align-items-center is-justify-content-space-between
              custom-border-bottom
            "
          >
            <div
              class="
                has-text-violet
                title
                is-size-6-5
                has-text-weight-normal
                mb-5
              "
            >
              {{ tender.name }}
            </div>
            <div class="" role="button">
              <ArrowUpIcon v-if="props.open" />
              <ArrowDownIcon v-else />
            </div>
          </div>
        </template>

        <div class="has-text-left mb-3 mt-2">
          <table class="table w-full">
            <tbody>
              <tr>
                <td>ID</td>
                <td>{{ tender.id }}</td>
              </tr>
              <tr>
                <td>Дата проведения:</td>
                <td>
                  <div class="is-size-6-5 has-text-weight-medium is-flex">
                    <span class="mr-2 mt-1">
                      <time-icon />
                    </span>

                    <span>
                      с
                      {{ tender.date_from | moment("DD.MM.YYYY HH:mm:ss") }}
                      <br />
                      по {{ tender.date_to | moment("DD.MM.YYYY HH:mm:ss") }}
                    </span>
                  </div>
                </td>
              </tr>
              <tr>
                <td>Категория:</td>
                <td>{{ getCategoryById(tender.categories) }}</td>
              </tr>
              <tr>
                <td>Проект:</td>
                <td>{{ getProjectById(tender.project) }}</td>
              </tr>
              <tr>
                <td>Предв. цена, руб:</td>
                <td>
                  <span class="has-text-weight-bold is-size-6-5">
                    {{ formatSpacesThousands(tender.budget) }} руб.
                  </span>
                </td>
              </tr>
              <tr>
                <td>Статус:</td>
                <td>
                  <span>
                    <div v-if="tender.status === 1">
                      <div class="control">
                        <div class="tags has-addons">
                          <span
                            class="tag has-background-success-light pl-1 pr-1"
                          >
                            <span class="tag-dot"> • </span>
                          </span>
                          <span class="tag has-background-success-light pl-0"
                            >ОТКРЫТ</span
                          >
                        </div>
                      </div>
                    </div>
                    <div v-else-if="tender.status === 2">
                      <div class="control">
                        <div class="tags has-addons">
                          <span class="tag has-background-info-light pl-1 pr-1">
                            <span class="tag-dot"> • </span>
                          </span>
                          <span class="tag has-background-info-light pl-0"
                            >ОКОНЧЕН</span
                          >
                        </div>
                      </div>
                    </div>
                    <div v-else>
                      <div class="control">
                        <div class="tags has-addons">
                          <span
                            class="tag has-background-warning-light pl-1 pr-1"
                          >
                            <span class="tag-dot"> • </span>
                          </span>
                          <span class="tag has-background-warning-light pl-0"
                            >ПОДВЕДЕНИЕ ИТОГОВ</span
                          >
                        </div>
                      </div>
                    </div>
                  </span>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </b-collapse>
      <div
        class="
          custom-card-footer
          pt-3
          has-text-left
          is-size-7-2
          has-text-weight-light
        "
        v-if="!isOpen"
      >
        <p class="is-flex mb-1">
          <span class="mr-3"> Статус: </span>
          <span>
            <div v-if="tender.status === 1">
              <div class="control">
                <div class="tags has-addons">
                  <span class="tag has-background-success-light pl-1 pr-1">
                    <span class="tag-dot"> • </span>
                  </span>
                  <span class="tag has-background-success-light pl-0"
                    >ОТКРЫТ</span
                  >
                </div>
              </div>
            </div>
            <div v-else-if="tender.status === 2">
              <div class="control">
                <div class="tags has-addons">
                  <span class="tag has-background-info-light pl-1 pr-1">
                    <span class="tag-dot"> • </span>
                  </span>
                  <span class="tag has-background-info-light pl-0"
                    >ОКОНЧЕН</span
                  >
                </div>
              </div>
            </div>
            <div v-else>
              <div class="control">
                <div class="tags has-addons">
                  <span class="tag has-background-warning-light pl-1 pr-1">
                    <span class="tag-dot"> • </span>
                  </span>
                  <span class="tag has-background-warning-light pl-0"
                    >ПОДВЕДЕНИЕ ИТОГОВ</span
                  >
                </div>
              </div>
            </div>
          </span>
        </p>
        <p>
          Предв. цена, руб:
          <span class="has-text-weight-bold is-size-6-5">
            {{ formatSpacesThousands(tender.budget) }} руб.
          </span>
        </p>
      </div>
    </div>
  </div>
</template>

<script>
import ArrowUpIcon from "@/components/icons/ArrowUpIcon.vue";
import ArrowDownIcon from "@/components/icons/ArrowDownIcon.vue";
import { filterSelects } from "./TenderListData";
import TimeIcon from "@/components/icons/TimeIcon.vue";

export default {
  components: { ArrowUpIcon, ArrowDownIcon, TimeIcon },
  data() {
    return {
      isOpen: false,
    };
  },
  mounted() {},
  methods: {
    getCategoryById(id) {
      const category = filterSelects["categories"].filter((item) => {
        return item.id == id;
      });
      if (category.length) return category[0].title;
      return false;
    },
    getProjectById(id) {
      const project = filterSelects.project.filter((item) => {
        return item.id == id;
      });

      if (project.length) return project[0].title;
      return false;
    },
  },
  props: {
    tender: {
      type: Object,
      default() {
        return {};
      },
    },
  },
};
</script>


<style scoped>
.mobile-card .table td {
  font-style: normal;
  font-weight: 300;
  font-size: 0.82rem;
  line-height: 0.82rem;
  color: #6f6f6f;
  border-bottom: 1px solid #e4e4e4;
}
</style>
