<template>
  <b-switch
    size="is-small"
    v-model="$store.state.tender.isHideInactive"
    @input="updateList"
  ></b-switch>
</template>

<script>
import { TenderListFullBasicMixin } from "./TenderListFullBasicMixin";

export default {
  components: {},
  mixins: [TenderListFullBasicMixin],
};
</script>
