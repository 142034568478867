<template>
  <div
    class="
      is-flex is-align-items-center is-justify-content-space-between
      border-1-bottom
    "
  >
    <div
      class="
        is-flex is-align-items-center is-justify-content-space-between is-flex-1
        border-gray-right
        pr-4
      "
    >
      <div class="">
        <div class="p-4 is-flex is-align-items-center table-list-filter-tags">
          <span class="is-size-5-5 mr-4 mb-1">Фильтры:</span>

          <div
            class="tag-is-medium-size mr-3"
            v-for="(value, filterName) in appliedFilters"
            v-bind:key="filterName"
          >
            <b-tag
              closable
              attached
              rounded
              @close="() => resetFilters(filterName)"
              v-if="value['value'] != null"
            >
              <span v-if="Array.isArray(value['value'])">
                от {{ value["value"][0] }} до {{ value["value"][1] }}
              </span>

              <span v-else-if="_filterSelects[filterName]">
                {{
                  _filterSelects[filterName].find(
                    (item) => item.id === value.value
                  ).title
                }}
              </span>
              <span
                v-else-if="
                  filterName === 'name' || filterName === 'description'
                "
              >
                {{ value["value"] }}
              </span>
              <span v-else> {{ value["value"] }} {{ filterName }} </span>
            </b-tag>
          </div>
        </div>
      </div>
      <div class="gear pr-2 pl-2">
        <button
          class="
            button
            is-primary is-small is-outlined is-rounded
            custom-button-outlined
          "
          @click="() => resetFilters()"
          v-if="Object.keys(appliedFilters).length"
        >
          <span class="mr-2">
            <close-icon color="#FFFFFF" />
          </span>
          <span>СБРОСИТЬ ФИЛЬТРЫ</span>
        </button>
      </div>
    </div>

    <div class="gear p-2">
      <button class="button is-white" @click="toggleShowSetup">
        <gear-icon />
      </button>
    </div>
  </div>
</template>

<script>
import { TenderListFullBasicMixin } from "./TenderListFullBasicMixin";
import GearIcon from "@/components/icons/GearIcon.vue";
import CloseIcon from "@/components/icons/CloseIcon.vue";

export default {
  components: { GearIcon, CloseIcon },
  mixins: [TenderListFullBasicMixin],
};
</script>

<style scoped>
</style>
